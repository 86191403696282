.avatar-editor-container {
    position: fixed;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  
  .avatar-editor-container img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    display: block;
  }
  
  .avatar-editor-container .controls {
    text-align: center;
    margin-top: 10px;
  }
  
  .image-container {
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.3s ease;
    border-radius: 50%;
  }
  
  .image-container:hover img {
    filter: blur(1px);
  }
  
  .pencil-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px; /* Height of the darker shade area */
    background: rgba(0, 0, 0, 0.5); /* Darker shade color with 50% opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    border-radius: 0 0 50% 50%;
  }
  
  /* .image-container:hover .pencil-icon {
    opacity: 1;
  } */