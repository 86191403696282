.home-faq {
padding: 12rem;
}

.row-title-text {
font-size: 17px;
font-weight: 600;
padding-top: 10px;
color: #151618;
}

.row-content-text {
margin: 10px 0;
}

.textfont{
font-size: 2.5rem;
}

@media (max-width: 844px) {
.home-faq {
padding: 4rem;
}
}



