@tailwind base;
@tailwind components;
@tailwind utilities;

/* ::-webkit-scrollbar {
width: 0;
} */

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sansita&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
/* Dosis and Inter  */
@import url('https://fonts.googleapis.com/css2?family=Dosis&family=Inter&display=swap');
/* @font-face {
font-family: 'Format';
src: url('./Utils/fonts/format-1452-master/format-1452-master/Format_1452.otf') format('truetype');
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Summer';
src: url('./Utils/fonts/with-summer/With\ Summer\ -\ TTF.ttf') format('truetype');
font-weight: normal;
font-style: normal;
} */

.inter{
  font-family: "Inter", sans-serif;
}
.poppins {
  font-family: "Poppins", sans-serif;
}

.bebas {
  font-family: "Bebas Neue", sans-serif;
}

.JustAnotherHand {
  font-family: "Just Another Hand", cursive;
}

.RussoOne {
  font-family: "Russo One";
}

.Sansita {
  font-family: "Sansita";
}

#close-arrow {
  transform: rotate(180deg);
}


.shadowSubscribe2 {
  box-shadow: 20px 10px 20px #86CB92;
}

.shadowSubscribe3 {
  box-shadow: 20px 10px 20px #FFC73B;
}

.shadowSubscribe {
  box-shadow: 20px 10px 20px #FDCF08;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(-5px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50px);
  }
}

.animate-slide-out {
  animation: slide-out 0.5s ease-out forwards;
}


.animate-fade-in {
  animation: fade-in 0.5s ease-in-out;
}

.animate-fade-in-up {
  animation: fade-in-up 0.6s ease-out;
}

.animate-slide-in {
  animation: fade-in-up 0.8s ease-out;
}

.loader2 {
  border: 4px solid #000000;
  border-radius: 50%;
  border-top: 4px solid #fff895;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari, etc.) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.scrollbar-hide {
  scrollbar-width: none; /* For Firefox */
}


/* Custom scrollbar styles */
.scrollbar-custom {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #cccccc #f5f5f5; /* Scrollbar thumb and track colors */
}

/* Webkit browsers (Chrome, Edge, Safari) */
.scrollbar-custom::-webkit-scrollbar {
  width: 4px;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: #3b2424; /* Track color */
  border-radius: 1px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: #ffefef; /* Scrollbar thumb color */
  border-radius: 1px;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background-color: #ffeeee; /* Darker on hover */
}

/* Two-line truncation */
.title-two-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.5em * 2);
  line-height: 1.5em;
}

/* Single-line truncation */
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 1.5em;
  line-height: 1.5em;
}