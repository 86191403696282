/* userprofile.css */

/* Update modal and modal-content styles */
.modal {
display: flex;
justify-content: center;
align-items: center;
position: fixed;
right: 0;
width: 100%;
height: 100%;
z-index: 1000;

}

.modal-content {
background-color: transparent;
border-radius: 10px;
border-color: transparent;
width: 100%;
max-height: 80%; /* Limit the maximum height */
overflow-y: auto; /* Enable vertical scrolling if needed */
}

/* Style the close button */
.modal-content button {
background-color: #FDCF08;
color: #fff;
border: none;
padding: 10px 20px;
margin-top: 10px;
border-radius: 5px;
cursor: pointer;
}

/* Style the input fields and labels */
.modal-content input,
.modal-content select {
width: 100%;
padding: 10px;
margin: 5px 0;
border: 1px solid #ccc;
border-radius: 5px;
}

/* Center the labels */
.modal-content label {
display: block;
margin-left: 2px;
}

/* Style the form elements */
.modal-content form {
display: flex;
flex-direction: column;
gap: 10px;
}

/* Add padding and margin to the form */
.modal-content form {
padding: 10px;
margin: 10px;
}

