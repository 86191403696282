@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
.instructor-contanier {
    width: 100%;
    margin-top: 1.5rem;
    padding-bottom: 3rem;
    border-bottom:2px solid #FDCF08;
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 10.936), rgba(0, 0, 0, 0.36)), url(../../Utils/Assests/about_us.jpg);
    height: 100%;
  }
  
  .row {
    margin-top: 2rem;
  }
  
  
  .instructor-headline{
    text-align: center;
    background-color: #FDCF08;
    color: #ffffff;
    padding: 8px 20px;
    box-shadow: 2px 2px #3b393998;
    cursor: pointer;
  }
  
  .inst-card {
    margin-bottom: 5rem;
    z-index: 10;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  
  .instructor-card-text{
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 9rem;
    bottom: 0;
    padding-top: 4rem;
    color: #ffff;
    background: linear-gradient(
      180deg,
      #ffffff00 0%,
      #FDCF08 100%
    );
  }
  
  /* h4:hover, h6 :hover{
    border: #ffff solid 3px;
  } */
  
  .inst-card .Box {
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.342);
    width: 18rem;
    height: 24rem;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: -20;
    background-position: center center;
  }
  
  @media only screen and (max-width: 720px) {
  
    .instructor-contanier {
      margin-bottom: none;
    }
  
    .row {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
    .inst-card {
      display: grid;
      align-items: center;
      justify-content: center;
      margin-bottom: 3rem;
    }
  
    .inst-card .Box {
      width: 17rem;
      height: 23rem;
    }
  
  }
  
  @media (orientation : landscape ) {
    .inst-card .Box {
      width: 21rem;
      height: 29rem;
    }
  }
 
  

  
 
  
  .submit-btn {
    padding: 1rem; /* Increased padding */
    font-size: 1.1rem; /* Increased font size */
  }
 
  .close:hover {
    background-color: #ffffff;
  }
  .close-button {
    font-size: 1.5rem;
  }
  
#domEl{
  width: 560px;
  height: 380px;
}
#domEl1{
  width: 560px;
  height: 380px;
}
.cont{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* box-sizing: border-box; */
  object-fit: cover;
}
.C-Name{
  margin-top: 110px;
  margin-left: 265px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 200;
  font-style: normal;
}
.C-Name1{
  margin-top: 110px;
  margin-left: 275px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 200;
  font-style: normal;
}
.C-no{
  margin-top: 83px;
  margin-left: 400px;
}
.C-Year{
  margin-top: 46px;
  margin-left: 376px;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.C-Date{
  margin-top: 15px;
  margin-left: 373px;
}
.file-label {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
}

.file-label:hover {
  background-color: #f0f0f0;
}

.file-input-container {
  display: flex;
  align-items: center;
}

.file-input-container span {
  margin-right: 8px;
}

/* .react-datepicker-ignore-onclickoutside{
   display: relative;
   
} */